import * as React from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  placeholder?: string;
  variant?: string;
  iconComponent?: React.ReactElement;
  onIconClick?: Function;
} & JSX.IntrinsicElements['input'];

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      placeholder,
      variant,
      iconComponent,
      onIconClick,
      ...props
    }: Props,
    ref,
  ) => {
    const renderIcon = () =>
      iconComponent &&
      React.cloneElement(iconComponent, {
        className: 'btn icon',
        onClick: onIconClick,
        width: 32,
        height: 32,
        fill: '',
      });
    return (
      <div
        className={clsx(
          'input-wrapper',
          className,
          variant && `input--${variant}`,
        )}
      >
        <input
          className="input-field"
          placeholder={placeholder}
          ref={ref}
          {...props}
        />
        {variant === 'with-icon' && renderIcon()}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
